:root {
  --stop-name: "";
}

.App {
  text-align: center;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.controls-wrapper {
  display: flex;
  justify-content: space-between;
}

.control {
  flex: 0 1 100%;
}
.center.metricsWidth {
  width:80%;
}
.center {
  margin:auto;
  width:15%;
  padding-top:5%;
}

.largeMarginTop {
  margin-top:4rem;
}

.page-title
{
    font-size:18px;
    padding-left:14px;
    display:none;
    padding-right:7px;
}

.flex-spacing
{
    flex-grow:1;
}

.flex-screen
{
    height:100%;
    display:flex;
    flex-direction:column;
}

.map-instructions
{
    border:1px solid #ccc;
    background-color:#fff;
    border-radius:5px;
    max-width:180px;
    padding:8px;
    opacity:1.0;
}

.app-tab-text {
    display:none;
}

.app-tab-icon {
    vertical-align: -6px;
}

.app-toolbar-space {
    height: 48px;
}

@media (min-width: 1050px)
{
    .map-fixed-half {
        position:fixed !important;
        top:48px;
        width:50%;
    }
}

.MuiSvgIcon-root.clear-filter
{
  color: #900;
}

.MuiSvgIcon-root.clear-filter, .MuiSvgIcon-root.add-filter
{
  font-size: 18px;
  vertical-align:-4px;
  opacity: 0.6;
}

.MuiSvgIcon-root.clear-filter:hover, .MuiSvgIcon-root.add-filter:hover {
  opacity: 1.0;
}

.MuiFormControl-root.inline-form-control {
    margin:8px 8px 8px 0px;
}

.MuiFormControl-root.inline-form-control label {
    white-space: nowrap;
}

.MuiInputLabel-shrink.MuiInputLabel-root {
    font-size: 13px;
    display:inline-block;
    line-height:1.5;
    transform: translate(0, 0);
}

label + .MuiInput-formControl {
    margin-top: 18px !important;
}

.MuiCheckbox-root
{
    padding:4px !important;
}

.app-logo {
    max-height: 38px;
    padding-top: 5px;
    padding-left: 10px;
    display:inline;
}

@media (min-width: 700px)
{
    .page-title {
       display:inline;
    }
}

@media (min-width: 900px)
{
    .app-tab-text {
        display:inline;
    }
}

@media (min-width: 900px)
{
    .date-time-ranges
    {
        white-space:nowrap;
    }
}

.on-hover > svg {
  transform: scale(1.6);
}

.on-hover::after {
  z-index: 1000;
  content: var(--stop-name);
  position: absolute;
  width: 240px;
  top: 1px;
  left: 23px;
  font-weight: bold;
  color: #0177bf; /* this needs to match the theme's main primary color */
  text-shadow: -1px 1px 0 #fff, 1px 1px 0 #fff, 1px -1px 0 #fff,
    -1px -1px 0 #fff;
}

.rv-discrete-color-legend-item__title
{
    font-size:13px;
    vertical-align:-2px;
}

.rv-discrete-color-legend-item.vertical
{
    padding:2px 10px;
}

.chart-section
{
    padding-bottom:25px;
}

.MuiTypography-root.MuiTypography-h6
{
    font-weight:normal;
}