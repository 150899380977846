.isochrone-map
{
    flex-grow:1;
}

.isochrone-controls,
.isochrone-legend,
.isochrone-instructions,
.isochrone-trip-info
{
    border:1px solid #ccc;
    background-color:#fff;
    border-radius:5px;
    opacity:0.9;
}

.isochrone-instructions
{
    max-width:180px;
    padding:8px;
    opacity:1.0;
}

.isochrone-controls
{
    padding:5px;
    min-width: 140px;
}

.isochrone-legend
{
    padding:2px 4px;
    white-space:nowrap;
}

.isochrone-trip
{
    padding:4px 4px;
}

.isochrone-legend-colors
{
    line-height:1.0;
}

.isochrone-control-select
{
    display:block;
    margin-bottom:5px;
}

.isochrone-legend-colors div
{
    opacity:0.45;
    height:15px;
    width:30px;
    overflow:hidden;
    display:inline-block;
}
.isochrone-legend-times div:first-child
{
    padding-left:15px;
}

.isochrone-legend-times div
{
    box-sizing:content-box;
    text-align:center;
    width:30px;
    display:inline-block;
}

.isochrone-routes
{
    overflow: auto;
    max-height: 150px;
}

.isochrone-routes li
{
    margin-bottom:0px;
    max-height: 25px;
}
